
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import axios from 'axios';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from './Login.module.css'

class Login extends Component {


  state = {
    passwordIncorrect: false,
    showPassword: false, // Initialize password visibility state

  }


  handlePasswordToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };


  componentDidMount() {
    axios.post(`/checkSession`, {
      sessionToken: `${localStorage.getItem("session")}`
    }).then((response) => {
      console.log(response.data);
      if (response.data.status == 0) {
        window.location.href = "/MyProfile"
      } else if (response.data.status == 1) {
        window.location.href = "/GetUserIG"
      }
      else if (response.data.status == 2) {
        window.location.href = "/WaitForApprove"
      } else if (response.data.status == 3) {
        // window.location.href = "/login"
      } else {

      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    console.log(
      data.get("EmailAddress") +
      data.get("password")
    )


    axios.post(`/login`, {
      EmailAddress: data.get("EmailAddress"),
      password: data.get("password")

    })
      .then((response) => {
        if (response.data.status === 0) {
          localStorage.setItem('session', response.data.session)
          localStorage.setItem('APIuserId', response.data.APIuserId)

          window.location.href = `/MyProfile`;
        } else {
          this.setState({
            passwordIncorrect: true
          })
          alert("Please try again")
          // window.location.reload();
        }


      })


  }

  render() {

    const { showPassword } = this.state;

    return (




      <div className={styles.backgroundcontainer}>


        <div className={styles.login_main_row}>
          <div className={`${styles.login_title} ${styles.gradienttext}`}>
            Login
          </div>


          <Form className={styles.formWrapper} onSubmit={this.handleSubmit}>
            <div className={`${styles.formgroup} form-group`}>
              <label className={`${styles.login_label} form-control-label `} >Email</label>
              <input className={`${styles.formcontrol} form-control `} autocomplete="off" id="EmailAddress" required name="EmailAddress" type="text" />
            </div>

            <div className={`${styles.formgroup} form-group`}>

              <label className={`${styles.login_label} form-control-label`}>Password</label>
              <div className={styles['password-input-wrapper']}>
                <input
                  autoComplete="off"
                  className={`${styles.formcontrol} form-control`}
                  id="password"
                  required
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                />
                <button
                  type="button"
                  onClick={this.handlePasswordToggle}

                  className={styles['password-toggle-button']}
                >
                  <img src={showPassword ? '/img/unshowpass.svg' : '/img/showpass.svg'} alt="Toggle Password" />
                </button>
              </div>
              <div className={styles['forgotpassword']}>
                <div onClick={() => { window.location.href = "/Forgotpass" }} style={{ cursor: "pointer" }}>Forgot password?</div>
                <div><span className={styles['donthaveanaccount']}>Don't have an account yet? </span><span onClick={() => { window.location.href = "/NewUsercreateProfile" }} style={{ cursor: "pointer", textDecorationLine: "underline" }}>sign up now</span></div>
              </div>
            </div>


            {this.state.passwordIncorrect === true ?
              <Col style={{ textAlign: 'left' }} xs={12}>
                <p className={styles['incorrectusenameorpassword']} >Incorrect password or username</p>
              </Col>
              : null}

            <Button type="submit" bsPrefix={styles.gradientButton}>Login</Button>

          </Form>
        </div>


      </div>

    );
  }
}





export default Login;
