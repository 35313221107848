
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import axios from 'axios';
import styles from './Login.module.css'

class GetUserIG extends Component {


  componentDidMount() {
    axios.post(`/checkSession`, {
      sessionToken: `${localStorage.getItem("session")}`
    }).then((response) => {
      console.log(response.data);
      if (response.data.status == 0) {
        window.location.href = "/MyProfile"
      } else if (response.data.status == 1) {
        // window.location.href = "/GetUserIG"
      } else if (response.data.status == 2) {
        window.location.href = "/WaitForApprove"
      } else if (response.data.status == 3) {
        window.location.href = "/login"
      } else {

      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    axios.post(`/getuseriglink`, {
      useriglink: data.get("useriglink"),
      sessionToken: `${localStorage.getItem("session")}`

    })
      .then((response) => {

        if (response.data.status === 0) {
          window.location.href = `/waitForApprove`;
        } else {
          alert("Please try again")
          // window.location.reload();
        }


      })


  }

  render() {

    return (


      <div className={styles.backgroundcontainer}>


        <div className={styles.login_main_row}>
          <div className={`${styles.login_title} ${styles.gradienttext}`}>
            Let's Start
          </div>
          <div style={{ marginBottom: "20px" }}>1. Verify your account by sending us a direct message on Instagram </div>
          <div style={{ marginBottom: "20px" }}>Click the IG logo to reach us easily </div>

          <div style={{ textAlign: "center" }} >
            <a target='_blank' href='https://www.instagram.com/backmysocials'>
              <img src="/img/ig.png" width="100" height="100" />
            </a>
          </div>

          <div style={{ marginTop: "50px" }}>2. Please fill in your Instagram username</div>
          <Form className={styles.formWrapper} onSubmit={this.handleSubmit}>
            <div className={`${styles.formgroup} form-group`}>
              <label className={`${styles.textlabel} form-control-label `} >Your Instagram username</label>
              <input className={`${styles.formcontrol} form-control `} id="useriglink" required name="useriglink" type="text" />
            </div>




            <div style={{ marginTop: "20px" }}>Click here when you are done sending us a message and filling your Instagram username </div>

            <Button type="submit" bsPrefix={styles.gradientButton}> I'm Done</Button>

          </Form>
        </div>


      </div>

    );
  }
}





export default GetUserIG;
