// UserGallery.jsx
import React, { Component } from 'react';
import axios from 'axios';
// import styles from './ChooseConnectedPage.module.css'; // Import the CSS module
import styles from './FirstPart.module.css'; // Import the CSS module
import BackgroundWithStars from './BackgroundWithStars'; // Import the CSS module
import BlocksSpinner from './BlocksSpinner'; // Import the CSS module


class ChooseConnectedPage extends Component {
  state = {
pagesArray:[],
viewportWidth: window.innerWidth,
widthToGoMobile : 1000,
showSpinner: false,

  };       
  

  componentDidMount() {
    this.fetchPages();
    window.addEventListener('resize', this.handleResize);

  }

  handleResize = () => {
    // Update the state with the new viewport width
    this.setState({ viewportWidth: window.innerWidth });
  };


  fetchPages = async () => {
    try {
      const response = await axios.post('/user/get_user_pages', {fbuserID: localStorage.getItem("fbuserID")});
      console.log(response.data)
      this.setState({pagesArray : response.data.pageIdsAndNames});
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  handleclick = async (padeID) => {
    var check = window.confirm("Are you sure this is your connected page?");
if(check){
    try {
      this.setState({showSpinner: true})
      const response = await axios.post('/user/choosepagetoiguser', {fbuserID: localStorage.getItem("fbuserID"), pageIDChosen: padeID});
      console.log(response.data)
      
      this.setState({showSpinner: false})
      // window.location.href = `/usergallery/${response.data.fbuserID}`   
      window.location.href = `/MyProfile`   

    } catch (error) {
      console.error('Error fetching images:', error);
    }
  }
  };

  render() {
    const { pagesArray } = this.state;
    const { viewportWidth } = this.state;

    return (
      <div className={styles.fullScreenContainer}>
       { this.state.showSpinner ? <BlocksSpinner/> : null} 
      {viewportWidth >= this.state.widthToGoMobile ? <BackgroundWithStars/> : null}
     <p className={styles.subtitle}>Please click on the page connected to you IG account</p>

     {pagesArray.map(item => (
            <div key={item.id} className={styles.imageCardBig}>
       <button  onClick={()=> this.handleclick(item.id)} className={styles.gradientButton}>{item.name}</button>
            </div>
          ))}

     <div className={styles.backgroundImage} />
   </div>


    );
  }
}

export default ChooseConnectedPage;
