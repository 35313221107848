
import React, { Component } from 'react';

import axios from 'axios';
import styles from './Login.module.css'
import Button from 'react-bootstrap/Button'
import CustomNavbar from './Navbar'; // Adjust the import path as necessary



class Affiliate extends Component {

  state = {
    Affiliate_uuid: "",
    Is_Affiliate: false,
    Affiliated_count: 0
  }

  componentDidMount() {
    axios.post(`/checkSession`, {
      sessionToken: `${localStorage.getItem("session")}`
    }).then((response) => {
      console.log(response.data);
      if (response.data.status == 0) {

        axios.post(`/check_Is_Affiliate`, {
          sessionToken: `${localStorage.getItem("session")}`
        }).then((responseA) => {

          if (responseA.data.status == 1) {

          }
          if (responseA.data.status == 0) {
            this.setState({ Affiliate_uuid: responseA.data.Affiliate_uuid, Affiliated_count: responseA.data.Affiliated_count, Is_Affiliate: true })

          }

        })

      } else if (response.data.status == 1) {
        window.location.href = "/GetUserIG"
      } else if (response.data.status == 2) {
        // window.location.href = "/WaitForApprove"
      } else if (response.data.status == 3) {
        window.location.href = "/login"
      } else {

      }
    })
  }

  generatelinkforAffiliate = (event) => {
    event.preventDefault();
    console.log("preess")
    axios.post(`/generatelinkforAffiliate`, {
      sessionToken: `${localStorage.getItem("session")}`


    })
      .then((response) => {
        console.log(response)
        if (response.data.status === 0) {
          this.setState({ Affiliate_uuid: response.data.Affiliate_uuid, user_Worth_Credits: Number(response.data.Affiliated_count), Affiliated_count: response.data.Affiliated_count, Is_Affiliate: true })
        } else {
          alert("Please try again")
          // window.location.reload();
        }


      })


  }


  copyToClipboard = (e) => {
    const el = document.createElement('textarea');
    el.value = `https://backmysocials.com/invite/${this.state.Affiliate_uuid}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    // Optionally, alert the user that the text has been copied
    alert('Link copied to clipboard!');
  };

  render() {

    return (

      <>
        < CustomNavbar />
        <div className={styles.backgroundcontainer}>


          <div className={styles.login_main_row}>

            {this.state.Is_Affiliate === false ?
              <>
                <div style={{ letterSpacing: "4px", fontWeight: "700" }} className={`${styles.login_title} ${styles.gradienttext}`}>
                  Join Our Affiliate Team
                </div>

                <div style={{ marginBottom: "20px" }}>You’ll get <span className={`${styles.price2}`}>$20</span> in credits for each qualified sign up</div>

                <Button style={{ maxWidth: "45%" }} onClick={(e) => this.generatelinkforAffiliate(e)} bsPrefix={styles.gradientButton}>Join Now</Button>
              </>
              :
              <>

                <div style={{ letterSpacing: "4px", fontWeight: "500" }} className={`${styles.login_title2} ${styles.gradienttext}`}>
                  My Affiliate
                </div>

                <div className={styles.wrapdiv2}>

                  <p>My Personal Link</p>
                  <p style={{ fontSize: "25px" }}>https://backmysocials.com/invite/{this.state.Affiliate_uuid}</p>

                  {/* <p style={{ fontSize: "25px" }}> http://localhost:3000/invite/{this.state.Affiliate_uuid}</p> */}


                  <Button className={styles.gradientButton} onClick={this.copyToClipboard}>Copy Link</Button>

                </div>

                <div className={styles.wrapdiv2}>
                  <div className={styles.wrapdiv3}>
                    <p>My Credits</p>
                    <p style={{ fontSize: "25px" }}>{Number(this.state.Affiliated_count) * 20} $</p>
                  </div>

                  <div className={styles.wrapdiv3}>
                    <p>Signups Counter</p>
                    <p style={{ fontSize: "25px" }} >{this.state.Affiliated_count} </p>
                  </div>

                </div>

              </>
            }

          </div>



        </div>
      </>
    )

  }
}





export default Affiliate;
