import React from 'react';

function HomePage() {
  const clientId = '897015875488471'; // Replace with your Instagram Client ID
  const redirectUri = encodeURIComponent('https://localhost:3001/success'); // Ensure this matches the redirect URI registered in the Instagram app settings

  const handleAuth = () => {
    const scope = 'user_profile,user_media';
    const instagramAuthUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;
    window.location.href = instagramAuthUrl;
  };

  return (
    <div>
      <button onClick={handleAuth}>Join with Instagram</button>
    </div>
  );
}

export default HomePage;
