import React, { Component } from 'react';
import axios from 'axios';


class Pro extends Component {

    componentDidMount() {
      this.loadFacebookSDK();
    }
  
    loadFacebookSDK() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: '895282089010675', // Replace {app-id} with your app ID
          cookie: true,
          xfbml      : true,
          version    : 'v19.0' 
        });
  
        window.FB.getLoginStatus(this.statusChangeCallback);
      };
  
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        js.async = true; js.defer = true; js.crossOrigin = 'anonymous';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  
    statusChangeCallback = (response) => {
      console.log('statusChangeCallback');
      console.log(response);

      if (response.status === 'connected') {
        axios.post('/api/instagram/pro', { response })
        .then(response => {
          localStorage.setItem("fbuserID", response.data.fbuserID)
window.location.href = `/ChooseConnectedPage/${response.data.fbuserID}`       
 })
        .catch(error => {
          console.error('Error exchanging code for token:', error);
         
        });
      
      } else {
        document.getElementById('status').innerHTML = 'Please log into this webpage.';
      }
    };
  
    checkLoginState = () => {
      window.FB.getLoginStatus(this.statusChangeCallback);
    };
  
    handleClick = () => {
      window.FB.login(this.checkLoginState, {
        // scope: 'public_profile,pages_show_list,instagram_basic,business_management,pages_read_engagement,instagram_manage_insights',
                // scope: 'pages_show_list,instagram_basic,business_management,pages_read_engagement,instagram_content_publish,ads_management',
                scope: 'pages_show_list,instagram_basic,business_management,pages_read_engagement',


      });
    };

  
    render() {
      return (
        <div>
          <button onClick={this.handleClick}>
            Login with Facebook
          </button>
          <div id="status"></div>
        </div>
      );
    }
  }
  

  

export default Pro;
