import React from 'react';
import { Link } from 'react-router-dom';

function SuccessPage() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Authentication Successful!</h1>
      <p>Your Instagram account has been successfully connected.</p>
      <p>Welcome to [Your App Name]! You can now access your Instagram data and features provided by our app.</p>
      
      {/* Link to home or dashboard */}
      <Link to="/" style={{ display: 'inline-block', marginTop: '20px', padding: '10px 20px', backgroundColor: '#007bff', color: '#ffffff', textDecoration: 'none', borderRadius: '5px' }}>Go to Dashboard</Link>
    </div>
  );
}

export default SuccessPage;
