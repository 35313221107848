
import React, { Component } from 'react';


class Redirect extends Component {


  componentDidMount() {
    window.location.href = "/login"
  }

  render() {


    return (




      <div >

      </div>

    );
  }
}





export default Redirect;
