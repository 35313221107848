
import React, { Component } from 'react';

import axios from 'axios';
import styles from './Login.module.css'

class WaitForApprove extends Component {


  componentDidMount() {
    axios.post(`/checkSession`, {
      sessionToken: `${localStorage.getItem("session")}`
    }).then((response) => {
      console.log(response.data);
      if (response.data.status == 0) {
        window.location.href = "/MyProfile"
      } else if (response.data.status == 1) {
        window.location.href = "/GetUserIG"
      } else if (response.data.status == 2) {
        // window.location.href = "/WaitForApprove"
      } else if (response.data.status == 3) {
        window.location.href = "/login"
      } else {

      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    axios.post(`/getuseriglink`, {
      useriglink: data.get("useriglink"),


    })
      .then((response) => {
        if (response.data.status === 0) {
          window.location.href = `/WaitForApprove`;
        } else {
          alert("Please try again")
          // window.location.reload();
        }


      })


  }

  render() {

    return (


      <div className={styles.backgroundcontainer}>


        <div className={styles.login_main_row}>
          <div className={`${styles.login_title} ${styles.gradienttext}`}>
            Thank you!
          </div>

          <div style={{ marginBottom: "20px" }}>We are currently checking your application</div>
          <div style={{ marginBottom: "20px" }}>You will get an email from us when approved </div>

        </div>


      </div>

    );
  }
}





export default WaitForApprove;
