// FirstPart.jsx
import React, { Component } from 'react';
import styles from './FirstPart.module.css'; // Import the CSS module
import BackgroundWithStars from './BackgroundWithStars'; // Import the CSS module
import axios from 'axios';




class FirstPart extends Component {
  state={
    viewportWidth: window.innerWidth,
    widthToGoMobile : 1000,
  }


  handleResize = () => {
    // Update the state with the new viewport width
    this.setState({ viewportWidth: window.innerWidth });
  };

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('resize', this.handleResize);
  }



  componentDidMount() {
    this.loadFacebookSDK();
    window.addEventListener('resize', this.handleResize);

  }

  loadFacebookSDK() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '895282089010675', // Replace {app-id} with your app ID
        cookie: true,
        xfbml      : true,
        version    : 'v19.0' 
      });

      window.FB.getLoginStatus(this.statusChangeCallback);
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      js.async = true; js.defer = true; js.crossOrigin = 'anonymous';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  statusChangeCallback = (response) => {
    console.log('statusChangeCallback');
    console.log(response);

    if (response.status === 'connected') {
      axios.post('/api/instagram/pro', { response })
      .then(response => {
        localStorage.setItem("fbuserID", response.data.fbuserID)
window.location.href = `/ChooseConnectedPage/${response.data.fbuserID}`       
})
      .catch(error => {
        console.error('Error exchanging code for token:', error);
       
      });
    
    } else {
      // document.getElementById('status').innerHTML = 'Please log into this webpage.';
    }
  };

  checkLoginState = () => {
    window.FB.getLoginStatus(this.statusChangeCallback);
  };

  handleClick = () => {
    window.FB.login(this.checkLoginState, {
      scope: 'public_profile,pages_show_list,instagram_basic,business_management,pages_read_engagement,instagram_manage_insights,instagram_manage_comments',
    });
  };

  render() {
    
    const { viewportWidth } = this.state;

    return (
      <div className={styles.fullScreenContainer}>
         {viewportWidth >= this.state.widthToGoMobile ? <BackgroundWithStars/> : null}
        <p className={styles.subtitle}>Welcome to <span className={`${styles.brand} ${styles.gradienttext}`}>BackMySocials</span> Free Trial</p>

        <button onClick={this.handleClick} className={styles.gradientButton}>Start Free Trial Now</button>
 
        <div className={styles.backgroundImage} />
      </div>
    );
  }
}

export default FirstPart;
