
import React from 'react';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import styles from './NewUsercreateProfile.module.css'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'




class NewUsercreateProfile extends React.Component {
  state = {
    passwordShown: false,
    passwordcheck: "",
    notmatch: true,
    showInput: false,
    isValidPassword: false, //password check ,
    plan: "",
    Affiliate_uuid: ""
  }




  componentDidMount() {
    this.parseQueryParams();
  }

  parseQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);
    const plan = queryParams.get('plan');
    const Affiliate_uuid = queryParams.get('Affiliate_uuid');

    this.setState({ plan, Affiliate_uuid });
  }

  submitProfileToServer = (event) => {
    event.preventDefault();
    // var result = window.confirm("");
    // if (result) {

    const data = new FormData(event.target);
    var dataToSend = {
      password: data.get("password") || "",
      EmailAddress: data.get("EmailAddress") || "",
      plan: this.state.plan,
      Affiliate_uuid: this.state.Affiliate_uuid

    }
    axios.post(`/createNewProfile`, {

      dataToSend

    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status == 0) {
          localStorage.setItem('session', response.data.sessionToken)
          window.location.href = `/GetUserIG`
        } else {
          alert(response.data.message)
          window.location.reload()
        }


      })
      .catch(function (error) {
        console.log(error);
      });

    // }
  }

  togglePassword = () => {
    this.setState(prevState => ({
      passwordShown: !prevState.passwordShown
    }));
  }


  checkpassmatch = (event) => {
    if (event.target.value !== this.state.passwordcheck) {
      this.setState({
        notmatch: true
      });
    } else {
      this.setState({
        notmatch: false
      });
    }
  }

  // validatePassword = (password) => {
  //   // Define password requirements (adjust as needed)
  //   const minLength = 8;
  //   const hasUpperCase = /[A-Z]/.test(password);
  //   const hasLowerCase = /[a-z]/.test(password);
  //   const hasNumber = /[0-9]/.test(password);
  //   const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

  //   // Check if all requirements are met
  //   return (
  //     password.length >= minLength &&
  //     hasUpperCase &&
  //     hasLowerCase &&
  //     hasNumber &&
  //     hasSpecialChar
  //   );
  // };

  putpasstostate = (event) => {
    this.setState({
      passwordcheck: event.target.value
    });

    // const newPassword = e.target.value;
    // const isValidPassword = this.validatePassword(newPassword);

    // this.setState({
    //   password: newPassword,
    //   isValidPassword: isValidPassword,
    // });

  }

  handleInputChange = (e) => {
    const userInput = e.target.textContent;
    this.setState({
      userInput,
      showInput: userInput.trim() !== '',
    });
  };

  render() {

    const { userInput, showInput } = this.state;

    return (




      <div className={styles.backgroundcontainer}>

        <div className={styles.login_main_row}>
          <div className={`${styles.login_title} ${styles.gradienttext}`}>
            Sign Up
          </div>


          <Form className={styles.formWrapper} onSubmit={this.submitProfileToServer}>

            <div className={`${styles.formgroup} form-group`} as={Col}>
              <label className={`${styles.login_label} form-control-label `}  >Email Address</label>
              <input className={`${styles.formcontrol} form-control `} autocomplete="off" required type="email" name="EmailAddress" />
            </div>


            <div className={`${styles.formgroup} form-group`} as={Col}>
              <label className={`${styles.login_label} form-control-label`}>Password</label>
              <div className={styles['password-input-wrapper']}>
                <input
                  autoComplete="off"
                  className={`${styles.formcontrol} form-control`}
                  onChange={(event) => {
                    this.putpasstostate(event);
                  }}
                  required
                  type={this.state.showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                />
                <button
                  type="button"
                  onClick={() => {
                    this.setState((prevState) => ({
                      showPassword: !prevState.showPassword,
                    }));
                  }}
                  className={styles['password-toggle-button']}
                >
                  <img
                    src={this.state.showPassword ? '/img/unshowpass.svg' : '/img/showpass.svg'}
                    alt="Toggle Password"
                  />
                </button>
              </div>
            </div>



            <div className={`${styles.formgroup} form-group`} as={Col}>
              <label className={`${styles.login_label} form-control-label `}>Type Password Again</label>
              <div className={styles['password-input-wrapper']}>


                <input
                  onInput={() => {
                    this.setState((prevState) => ({
                      showInput: true,
                    }));
                  }}
                  className={`${styles.formcontrol} form-control `}
                  autocomplete="off" onChange={(event) => this.checkpassmatch((event))}
                  required type={this.state.showPassword ? 'text' : 'password'}
                  placeholder="" id="password2" name="password2" />
                <button
                  type="button"
                  onClick={() => {
                    this.setState((prevState) => ({
                      showPassword: !prevState.showPassword,
                    }));
                  }}
                  className={styles['password-toggle-button']}
                >
                  <img
                    src={this.state.showPassword ? '/img/unshowpass.svg' : '/img/showpass.svg'}
                    alt="Toggle Password"
                  />
                </button>
              </div>
              {this.state.showInput === true ?
                <div>
                  {this.state.notmatch === true ?
                    <Col style={{ textAlign: 'left', marginTop: "24px" }} xs={12}>
                      <p className={styles['incorrectusenameorpassword']}>Passwords don't match</p>
                    </Col>
                    : <Col style={{ textAlign: 'left' }} xs={12}>
                      <p className={styles['passwordsmatch']}>Passwords match</p>
                    </Col>}
                </div>
                : null
              }

              <div className={styles['forgotpassword']}>
                <div ><span className={styles['donthaveanaccount']}>Already have an account? </span><span onClick={() => { window.location.href = '/login' }} style={{ cursor: 'pointer', textDecorationLine: "underline" }}>Login</span></div>
              </div>
            </div>


            <div className={styles.flexdirectiocolum}>
              <Button disabled={this.state.notmatch === true ? true : false} type="submit" bsPrefix={this.state.notmatch === true ? styles.gradientButtondisabled : styles.gradientButton}>Sign up</Button>
              <div className={styles.Byproceeding}>
                By proceeding, you agree to the <a className={styles.TermsOfLink}>Terms of Service</a> and <a className={styles.TermsOfLink}>Privacy Policy</a>
              </div>
            </div>
          </Form>
        </div>


      </div>


    )
  }
}







export default NewUsercreateProfile;



