// Navbar.js
import React, { Component } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import styles from './Navbar.module.css'; // Import your CSS module

export default class CustomNavbar extends Component {

  state = {
    activeLink: 'about', // Start with 'home' as the default active link
  };

  setActiveLink = (activeLink) => {
    this.setState({ activeLink }, () => {
      document.querySelector("#NavbarToggle").click();
    });
  };

  logout = () => {
    var result = window.confirm("Sure You Want To Logout?");
    if (result) {
    localStorage.removeItem("APIuserId");
    localStorage.removeItem("session");
    window.location.href = "/"
    // localStorage.clear();
  }}

  render() {
    const { activeLink } = this.state;
    const isWaitingList = window.location.href.includes('login') || window.location.href.includes('NewUsercreateProfile');

    return (
      <Navbar fixed="top" expand="lg" className={styles.navbar}>
        <Container fluid>
          <Navbar.Brand href="/" className={`${styles.brand} ${styles.gradienttext}`}>
          BackMySocials
          </Navbar.Brand>
          <Navbar.Toggle id="NavbarToggle" className={styles.navbartoggler}  aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className={styles.navbarCollapse}>

         { isWaitingList ?
             <Nav className={styles.navCenter}>
           
           </Nav>
         
         : 
<>
            <Nav className={styles.navCenter}>
              <Nav.Link href="/MyProfile" className={activeLink === 'My Profile' ? `${styles.navLink} ${styles.active}` : styles.navLink}
                onClick={() => this.setActiveLink('My Profile')}>My Profile</Nav.Link>
              <Nav.Link href="/Affiliate"  className={activeLink === 'Affiliate' ? `${styles.navLink} ${styles.active}` : styles.navLink}
                onClick={() => this.setActiveLink('Affiliate')}>Affiliate</Nav.Link>
              <Nav.Link href="/Settings" className={activeLink === 'Settings' ? `${styles.navLink} ${styles.active}` : styles.navLink}
                onClick={() => this.setActiveLink('Settings')}>Settings</Nav.Link>
        
            </Nav>
                     

            <Nav className={styles.navRight}>
              {/* <Button onClick={() => this.setActiveLink('pricing')} href="#pricing" className={styles.startNowButton}>Start Now</Button>
              <Button  onClick={() => this.setActiveLink('pricing')} href="#pricing"  className={styles.signInButton}>Sign In</Button> */}
              <Button onClick={this.logout}  className={styles.signInButton}>Logout</Button>
            </Nav>
            </>
            }
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
