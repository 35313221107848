// UserGallery.jsx
import React, { Component } from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
// import styles from './UserGallery.module.css'; // Import the CSS module
import styles from './FirstPart.module.css'; // Import the CSS module
import BackgroundWithStars from './BackgroundWithStars'; // Import the CSS module
import BlocksSpinner from './BlocksSpinner'; // Import the CSS module
import CustomNavbar from './Navbar'; // Adjust the import path as necessary


class API_UserGallery extends Component {
  state = {
    currentPage:1,
    media: [],
    selectedImages: {},
    profile_picture_url:"",
    user_details:"",
    viewportWidth: window.innerWidth,
widthToGoMobile : 1000,
showSpinner: true,

  };

  componentDidMount() {
    axios.post(`/checkSession`, {
      sessionToken: `${localStorage.getItem("session")}`
    }).then((response) => {
      console.log(response.data);
      if (response.data.status == 0) {
      this.fetchImages()
      } else if (response.data.status == 1) {
        window.location.href = "/GetUserIG"
      } else if (response.data.status == 2) {
        window.location.href = "/WaitForApprove"
      }else if (response.data.status == 3) {
        window.location.href = "/login"
      } else {

      }
    })


  }

  handleResize = () => {
    // Update the state with the new viewport width
    this.setState({ viewportWidth: window.innerWidth });
  };

  fetchImages = async () => {
    this.setState({showSpinner: true})
    try {
      const response = await axios.post('/user/displayPostsOnGallery',{userId: localStorage.getItem("APIuserId")});
      console.log(response.data)
      this.setState({showSpinner: false,profile_picture_url:response.data.profile_picture_url, media: response.data.user_media_array || [], user_details: response.data.user_details });
    } catch (error) {
      console.error('Error fetching images:', error);
      this.setState({showSpinner: false, media: []});

    }
  };


  LoadMoreFiles = () => {
    this.setState({showSpinner: true})
    // Increment the current page in the state
    const nextPage = this.state.currentPage + 1;

    // Prepare the request body or query parameters
    const requestData = {
        userId: localStorage.getItem("APIuserId"),  // Ensure you have userId in your state or derived from props
        page: nextPage,
        limit: 50  // As an example, fetching 50 items per page
    };

    // Make a POST request to fetch more files
    fetch('/user/displayPostsOnGallery', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
    })
    .then(response => response.json())
    .then(data => {
        if (data && data.user_media_array) {
            this.setState(prevState => ({
                media: [...prevState.media, ...data.user_media_array],
                currentPage: nextPage,  // Update the current page,
               showSpinner: false
            }));
        }
    })
    .catch(error => {
        console.error('Failed to load more files:', error);
    });
};




  render() {
    const { media, selectedImages, viewportWidth } = this.state;
    const anySelected = Object.values(selectedImages).some(isSelected => isSelected);

    return (
      <>
        < CustomNavbar />
      <div className={styles.gallery}>
         {viewportWidth >= this.state.widthToGoMobile ? <BackgroundWithStars/> : null}
         { this.state.showSpinner ? <BlocksSpinner/> : null} 
         {media.length === 0 ? <div className={styles.nothingtoshow}>
          {/* <p className={styles.ptitlestyle}> Nothing to show here yet...</p>   */}
         </div> : 
         <>
        <div className={styles.topdiv}>
        <img src={this.state.profile_picture_url} className={styles.profile_picture_image} />
          <div className={styles.usernameanddetails}>
            <div>
            <p className={styles.ptitlestyle}>{this.state.user_details.username}  {viewportWidth >= this.state.widthToGoMobile ? "|" : <br/>} {this.state.user_details.name}</p>  
            </div>
            <div>
            <p className={styles.ptitlestyle}>Followers: {this.state.user_details.followers_count} {viewportWidth >= this.state.widthToGoMobile ? "|" : <br/>}  Following: {this.state.user_details.following_count} {viewportWidth >= this.state.widthToGoMobile ? "|" : <br/>} Media: {this.state.user_details.media_count} </p>  
            </div>
            {viewportWidth >= this.state.widthToGoMobile ? 
            <div>
            <p className={styles.ptitlestyle}>"{this.state.user_details.biography}"</p>  
            </div>
         : null}
         
      
           
          </div>
        </div>

     

        <div className={styles.imageGrid}>
          {media.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map(item => (
 <>
            {item.media_url ? 
              <>
            <div key={item.id} className={styles.imageCard}>
              {item.media_type === "VIDEO" ?
              <>
              <video controls className={styles.image} >
  <source src={item.media_url} type="video/mp4"/>
 </video>
        
             </>

                :
                <>

              <img src={item.media_url} alt={item.caption} className={styles.image} />

          </>

            }
            </div>
            </> : 
              <>
              <div className={styles.image} >
Reel Not Available For Display
<a className={styles.aimagelink} href={item.permalink}>Click to view</a>
 </div>
             </>
            }
            </>
          ))}
        </div>

        <div className={styles.imageGrid}>
    <button onClick={this.LoadMoreFiles} className={styles.gradientButtonSmall}>Load More</button>
        </div>

        </>
        }
      </div>
      </>
    );
  }
}

export default API_UserGallery;
