import React from 'react';
import { Blocks, ColorRing } from 'react-loader-spinner';
import './index.css';  // Ensure this CSS file is created

function BlocksSpinner() {
  return (
    <div className="spinner-container">
      {/* <Blocks
        height="100"
        width="100"
        color="#4fa94d" // Default color, will try to override with CSS
        ariaLabel="blocks-loading"
        wrapperClass="blocks-wrapper"
        visible={true}
      /> */}
      <ColorRing
  visible={true}
  height="100"
  width="100"
  ariaLabel="color-ring-loading"
  wrapperStyle={{}}
  wrapperClass="color-ring-wrapper"
  colors={["#7920ca","#d62782","#f5ac21","#7920ca","#d62782","#f5ac21"]}
  />
    </div>
  );
}

export default BlocksSpinner;

