import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function InstagramRedirectHandler() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the code from URL query parameters
    const query = new URLSearchParams(location.search);
    const code = query.get('code');

    if (code) {
   
      // Send the code to your backend to exchange for an access token
      axios.post('http://localhost:3000/api/instagram/callback', { code })
        .then(response => {
          // Handle success, such as storing the access token or redirecting
          console.log('Access Token:', response.data.access_token);
          navigate('/success'); // Redirecting to a success page
        })
        .catch(error => {
          console.error('Error exchanging code for token:', error);
          navigate('/error'); // Redirecting to an error page
        });
    } else {
      // No code in URL, handle accordingly
      navigate('/'); // Redirecting back to the home page or error page
    }
  }, [location, navigate]);

  // Optionally, display a loading message or spinner
  return (
    <div>Processing Instagram authentication...</div>
  );
}

export default InstagramRedirectHandler;
