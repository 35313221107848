import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InstagramRedirectHandler from './InstagramRedirectHandler'; // Adjust the import path as necessary
import HomePage from './HomePage'; // Your other components
import SuccessPage from './SuccessPage';
import UserGallery from './UserGallery';
import Pro from './Pro';
import ChooseConnectedPage from './ChooseConnectedPage';
import FirstPart from './FirstPart';

//API
import API_UserGallery from './API_UserGallery';

//general
import Login from './Login';
import NewUsercreateProfile from './NewUsercreateProfile';
import GetUserIG from './GetUserIG';
import WaitForApprove from './WaitForApprove';

import CustomNavbar from './Navbar'; // Adjust the import path as necessary
import Redirect from './Redirect'; // Adjust the import path as necessary
import Affiliate from './Affiliate'; // Adjust the import path as necessary



//css
import 'bootstrap/dist/css/bootstrap.min.css';





// import ErrorPage from './ErrorPage';

function App() {
  return (
    <Router>

      <Routes>
        <Route path="/HomePage" exact element={<HomePage />} />
        <Route path="/success" exact element={<InstagramRedirectHandler />} />
        <Route path="/success3" exact element={<SuccessPage />} />
        <Route path="/usergallery/:fbuserID?" exact element={<UserGallery />} />
        <Route path="/Pro" exact element={<Pro />} />

        <Route path="/FirstPart" exact element={<FirstPart />} />

        <Route path="/ChooseConnectedPage/:fbuserID?" exact element={<ChooseConnectedPage />} />

        {/* API */}
        <Route path="/MyProfile" exact element={<API_UserGallery />} />
        <Route path="/Affiliate" exact element={<Affiliate />} />
        {/* <Route path="/Settings" exact element={<API_UserGallery />} /> */}


        {/* API */}


        {/* general */}
        <Route path="/Login" exact element={<Login />} />
        <Route path="/NewUsercreateProfile" exact element={<NewUsercreateProfile />} />
        <Route path="/GetUserIG" exact element={<GetUserIG />} />
        <Route path="/WaitForApprove" exact element={<WaitForApprove />} />


        <Route path="/" exact element={<Login />} />

        <Route path="/*" element={<Redirect />} />













      </Routes>
    </Router>
  );
}

export default App;
