// UserGallery.jsx
import React, { Component } from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
// import styles from './UserGallery.module.css'; // Import the CSS module
import styles from './FirstPart.module.css'; // Import the CSS module
import BackgroundWithStars from './BackgroundWithStars'; // Import the CSS module


class UserGallery extends Component {
  state = {
    media: [],
    selectedImages: {},
    profile_picture_url:"",
    user_details:"",
    viewportWidth: window.innerWidth,
widthToGoMobile : 1000,
  };

  componentDidMount() {
    this.fetchImages();
    window.addEventListener('resize', this.handleResize);

  }

  handleResize = () => {
    // Update the state with the new viewport width
    this.setState({ viewportWidth: window.innerWidth });
  };

  fetchImages = async () => {
    try {
      const response = await axios.post('/user/getAllUserPosts',{fbuserID: localStorage.getItem("fbuserID")});
      console.log(response.data)
      this.setState({profile_picture_url:response.data.profile_picture_url, media: response.data.user_media_array || [], user_details: response.data.user_details });
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  handleSelectImage = (id) => {
    this.setState(prevState => ({
      selectedImages: {
        ...prevState.selectedImages,
        [id]: !prevState.selectedImages[id]
      }
    }));
  };

  selectAll = () => {
    const allSelected = this.state.media.reduce((selected, image) => {
      selected[image.id] = true;
      return selected;
    }, {});
    this.setState({ selectedImages: allSelected });
  };

  downloadSelected = async () => {
    const zip = new JSZip();
    const { media, selectedImages } = this.state;

    const selectedMedia = media.filter(item => selectedImages[item.id]);
    for (let item of selectedMedia) {
      const response = await axios.get(item.media_url, { responseType: 'blob' });
      zip.file(`${item.id}.jpg`, response.data);
    }

    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'images.zip');
    });
  };

  render() {
    const { media, selectedImages, viewportWidth } = this.state;
    const anySelected = Object.values(selectedImages).some(isSelected => isSelected);

    return (
      <div className={styles.gallery}>
         {viewportWidth >= this.state.widthToGoMobile ? <BackgroundWithStars/> : null}
        <div className={styles.topdiv}>
        <img src={this.state.profile_picture_url} className={styles.profile_picture_image} />
          <div className={styles.usernameanddetails}>
            <div>
            <p className={styles.ptitlestyle}>{this.state.user_details.username}  {viewportWidth >= this.state.widthToGoMobile ? "|" : <br/>} {this.state.user_details.name}</p>  
            </div>
            <div>
            <p className={styles.secondtitlestyle}>Followers: {this.state.user_details.followers_count} {viewportWidth >= this.state.widthToGoMobile ? "|" : <br/>}  Following: {this.state.user_details.follows_count} {viewportWidth >= this.state.widthToGoMobile ? "|" : <br/>} Media: {this.state.user_details.media_count} </p>  
            </div>
            <div>
            <p className={styles.secondtitlestyle}>"{this.state.user_details.biography}"</p>  
            </div>

         
      
           
          </div>
        </div>

        <div className={styles.imageGrid}>
        <button onClick={this.selectAll} className={styles.gradientButtonSmall}>Select All</button>
        <button onClick={this.downloadSelected} className={styles.gradientButtonSmall} disabled={!anySelected}>Download</button>
        </div>

        <div className={styles.imageGrid}>
          {media.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map(item => (
 <>
            {item.media_url ? 
              <>
            <div key={item.id} className={styles.imageCard}>
              {item.media_type === "VIDEO" ?
              <>
              <video className={styles.image} >
  <source src={item.media_url} type="video/mp4"/>
 </video>
               <input
                 type="checkbox"
                 checked={!!selectedImages[item.id]}
                 onChange={() => this.handleSelectImage(item.id)}
                 className={styles.checkbox}
               />
             </>

                :
                <>

              <img src={item.media_url} alt={item.caption} className={styles.image} />
              <input
                type="checkbox"
                checked={!!selectedImages[item.id]}
                onChange={() => this.handleSelectImage(item.id)}
                className={styles.checkbox}
              />
          </>

            }
            </div>
            </> : 
              <>
              <div className={styles.image} >
Reel Not Available For Display
<a className={styles.aimagelink} href={item.permalink}>Click to view</a>
 </div>
             </>
            }
            </>
          ))}
        </div>
      </div>
    );
  }
}

export default UserGallery;
